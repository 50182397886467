<template>
    <div class="balance-modal">
        <div class="balance-modal_head">
            <img :src="this.imageSelector(wallet.ps.logo_url)" :alt="wallet.ps.alt">
            <span v-html="$content('balance_modal_title')"></span>
        </div>
        <h1 class="balance-modal_amount">${{ $utils.spacesAmount(wallet.balances.amount) }}</h1>
        <div class="balance-modal_field" v-for="field in fields">
            <span v-html="$content(`balance_${ field }`)"></span>
            <span>{{ $store.getters.static.symbol }} {{ $utils.spacesAmount(wallet.balances[field]) }}</span>
        </div>
        <div class="balance-modal_btn">
            <ProjectButton variant="app">
                <div
                    @click="$modal.open('Operation', {
                        width: '409px',
                        xColor: 'var(--main-app)',
                        type: 'withdraw'})"
                    v-html="$content('balance_modal_confirm')"></div>
            </ProjectButton>
        </div>
    </div>
</template>

<script>
import ProjectButton from "@/components/Buttons/ProjectButton";
export default {
    name: "Balance",
    components: {ProjectButton},
    data() {
        return {
            fields: ['deposit', 'referral_charges', 'structural_charges', 'earnings', 'investment_payout', 'can_withdraw']
        }
    },
    computed: {
        wallet() {
            return this.$store.getters.wallet
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .balance-modal {
        background: white;
        border-radius: 10px;
        padding: 34px 37px;

        &_head {

            & img {
                width: 34px;
            }

            & span {
                font-family: Montserrat-Light, sans-serif;
                font-size: 16px;
                color: var(--main-titles);
                margin-left: 13px;
            }
        }

        &_field {
            display: flex;
            justify-content: space-between;
            font-family: Montserrat-Light, sans-serif;

            & span {

                &:nth-child(1) {
                    font-size: 13px;
                    color: grey;
                }

                &:nth-child(2) {
                    font-size: 18px;
                    color: var(--main-dark);
                }
            }
        }

        &_amount {
            margin: 27px 0 30px 0;
            background: var(--main-gradient);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 63px;
            font-weight: bold;
            font-family: "Segoe UI Light", sans-serif;
            //text-overflow: ellipsis;
            white-space: nowrap;
            //overflow: hidden;

            @include only-xs {
                font-size: 50px;
            }

        }

        &_btn {
            margin-top: 27px;
        }
    }
</style>